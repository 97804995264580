import { ErpTableOptions } from '@/types/type';
import { defineComponent, ref, toRaw } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 160,
      value: '',
      type: 'input'
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '签约主体',
      prop: 'contractPrincipalName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '订单状态',
      prop: 'status',
      propDesc: 'statusDesc',
      minWidth: 130,
      value: [1, 4],
      type: 'multiSelect',
      options: 'order_status',
    },
    {
      label: '提货状态',
      prop: 'pickStatus',
      propDesc: 'pickStatusDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'pick_status',
    },
    {
      label: '客户是否结清',
      prop: 'settleStatus',
      propDesc: 'settleStatusDesc',
      minWidth: 162,
      value: '',
      type: 'signSelect',
      options: [
        {
          value: 1,
          label: '已结清',
        },
        {
          value: 0,
          label: '待结清',
        },
      ],
    },
    {
      label: '应开票金额',
      prop: 'arAmountManual',
      propDesc: 'arAmountManualDesc',
      minWidth: 140,
      value: [],
      type: 'number',
      filterProp: ['arAmountManualMinVal', 'arAmountManualMaxVal'],
    },
    {
      label: '未开票金额',
      minWidth: 140,
      prop: 'notInvoicedAmount',
      propDesc: 'notInvoicedAmountDesc',
      value: [],
      type: 'number',
      filterProp: ['notInvoicedAmountMinVal', 'notInvoicedAmountMaxVal'],
    },
    {
      label: '不开票金额',
      prop: 'nonInvoicedAmount',
      propDesc: 'nonInvoicedAmountDesc',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['nonInvoicedAmountMinVal', 'nonInvoicedAmountMaxVal'],
    },
    {
      label: '已开货物发票金额',
      prop: 'goodsInvoicedAmount',
      propDesc: 'goodsInvoicedAmountDesc',
      minWidth: 190,
      type: 'number',
      value: [],
      filterProp: ['goodsInvoicedAmountMinVal', 'goodsInvoicedAmountMaxVal'],
    },
    {
      label: '已开服务发票金额',
      prop: 'serviceInvoicedAmount',
      propDesc: 'serviceInvoicedAmountDesc',

      minWidth: 190,
      type: 'number',

      value: [],
      filterProp: [
        'serviceInvoicedAmountMinVal',
        'serviceInvoicedAmountMaxVal',
      ],
    },
    {
      label: '已开货物发票税额',
      prop: 'goodsInvoicedTaxAmount',
      propDesc: 'goodsInvoicedTaxAmountDesc',
      minWidth: 190,
      type: 'number',
      value: [],
      filterProp: [
        'goodsInvoicedTaxAmountMinVal',
        'goodsInvoicedTaxAmountMaxVal',
      ],
    },
    {
      label: '已开服务发票税额',
      prop: 'serviceInvoicedTaxAmount',
      propDesc: 'serviceInvoicedTaxAmountDesc',
      minWidth: 190,
      type: 'number',
      value: [],
      filterProp: [
        'serviceInvoicedTaxAmountMinVal',
        'serviceInvoicedTaxAmountMaxVal',
      ],
    },
    {
      label: '应来票金额',
      prop: 'apAmountManual',
      propDesc: 'apAmountManualDesc',
      minWidth: 140,
      value: [],
      type: 'number',
      filterProp: ['apAmountManualMinVal', 'apAmountManualMaxVal'],
    },
    {
      label: '未来票金额',
      prop: 'notApAmountManual',
      propDesc: 'notApAmountManualDesc',
      minWidth: 140,
      value: [],
      type: 'number',
      filterProp: ['notApAmountManualMinVal', 'notApAmountManualMaxVal'],
    },
    {
      label: '已来货物发票金额',
      prop: 'goodsInvoicedInputAmount',
      propDesc: 'goodsInvoicedInputAmountDesc',
      minWidth: 190,
      value: [],
      type: 'number',
      filterProp: [
        'goodsInvoicedInputAmountMinVal',
        'goodsInvoicedInputAmountMaxVal',
      ],
    },
    {
      label: '已来服务发票金额',
      prop: 'serviceInvoicedInputAmount',
      propDesc: 'serviceInvoicedInputAmountDesc',
      minWidth: 190,
      value: [],
      type: 'number',
      filterProp: [
        'serviceInvoicedInputAmountMinVal',
        'serviceInvoicedInputAmountMaxVal',
      ],
    },
    {
      label: '已来货物发票税额',
      prop: 'goodsInvoicedInputTaxAmount',
      propDesc: 'goodsInvoicedInputTaxAmountDesc',
      minWidth: 190,
      value: [],
      type: 'number',
      filterProp: [
        'goodsInvoicedInputTaxAmountMinVal',
        'goodsInvoicedInputTaxAmountMaxVal',
      ],
    },
    {
      label: '已来服务发票税额',
      prop: 'serviceInvoicedInputTaxAmount',
      propDesc: 'serviceInvoicedInputTaxAmountDesc',
      minWidth: 190,
      value: [],
      type: 'number',
      filterProp: [
        'serviceInvoicedInputTaxAmountMinVal',
        'serviceInvoicedInputTaxAmountMaxVal',
      ],
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 85,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
            <el-button type="primary"  plain  v-if="hasPermission('erp:output:auditOutput') && row.needAuditCount>0" @click='addContract(row)'>审核</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          const addContract = (row) => {
            Instance.setupState.addContract(toRaw(row));
          };

          return { addContract };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
