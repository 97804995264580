
import { defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import saveAuditOutPut from './components/saveAuditOutPut.vue';
import batchDialog from './batchDialog.vue';
import { erpConfirm, errorMessage, exportFile, loadPage } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
import router from '@/router';
export default defineComponent({
  name: 'Invoice',
  components: { ErpList, saveAuditOutPut, batchDialog },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const { startTimer, timeStatus } = useTimer();
    const formType = ref(1);
    const data = reactive({
      rowData: null,
      outputAuditId: null,
      detailsData: null,
    });

    const formData = reactive({
      invoiceVariety: '1',
    });
    const filterDialog = ref(false);

    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    // 审核
    const auditList = () => {
      router.push('/erp/financial/invoiceAuditList');
    };

    // 路由跳转
    const openSingleDialog = () => {
      router.push('/erp/financial/invoiceLog');
    };

    // 导出
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/invoice/output/exportOutputList',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };
    // 查看详情
    const rowClick = (row, column) => {
      const label = column?.label;
      if (label !== '操作') {
        loadPage('invoiceDetail', {
          id: row.orderId,
          version: row.version,
        });
      }
    };

    // 审核
    const addContract = (row) => {
      if (row.needAuditCount > 1) {
        rowClick(row, { label: '查看' });
      } else {
        data.outputAuditId = row.outputAuditId;
        data.detailsData = row;
        formType.value = 1;
        showVisible();
      }
    };
    const openBatchDialog = () => {
      filterDialog.value = true;
      formData.invoiceVariety = '1';
    };
    const sureBatchDialog = () => {
      const { invoiceVariety } = formData;
      loadPage('batchPage', {
        invoiceVariety,
      });
    };
    return {
      sureBatchDialog,
      openBatchDialog,
      filterDialog,
      formData,
      addContract,
      formType,
      openSingleDialog,
      ...toRefs(data),
      auditList,
      exportFileFn,
      cancel,
      closeVisible,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
    };
  },
});
