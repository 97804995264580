<template>
  <div>
    <batch-voice-table
      ref="batchVoice"
      :invoiceVariety="invoiceVariety"
      v-show="showType == 1"
      @success="batchSuccessCallback"
      @edit="editRowCallback"
      @cancel="batchCancelCallback"
    ></batch-voice-table>
    <edit-invoice
      v-if="showType == 2"
      :row="rowData"
      :rowIndex="rowIndex"
      @cancel="cancelEdit"
      @editBack="editLocalBack"
      @success="editSuccessCallback"
    ></edit-invoice>
  </div>
</template>
<script>
import batchVoiceTable from './components/batchVoiceTable.vue';
import editInvoice from './components/editInvoice.vue';
export default {
  props: {
    invoiceVariety: {
      type: [Number, String], // 1 货物发票   2 服务发票
      default: '',
    },
  },
  components: {
    batchVoiceTable,
    editInvoice,
  },
  data() {
    return {
      showType: 1,
      rowData: {},
    };
  },
  methods: {
    editRowCallback(row, rowIndex) {
      this.rowData = row;
      this.showType = 2;
      this.rowIndex = rowIndex;
    },
    cancelEdit() {
      this.showType = 1;
    },
    editSuccessCallback() {
      this.showType = 1;
      this.$refs.batchVoice.getDatas();
      this.$emit('upldateList');
    },
    editLocalBack(row, index) {
      this.showType = 1;
      this.$refs.batchVoice.updateRow(row, index);
    },
    batchCancelCallback() {
      this.$emit('cancel');
    },
    batchSuccessCallback() {
      this.$emit('success', true);
    },
  },
};
</script>
